<template>
  <div>
    <div class="col-6 pl-0 mb-3">
      <InputSelectLanguage
        ref="inputLang"
        @changeLang="changeLanguage"
        v-if="isFetch"
      />
    </div>

    <b-form-group label="Welcome Message" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        :options="WelcomeOpt"
        :aria-describedby="ariaDescribedby"
        v-model="form.welcome_message_type"
        name="welcome-screen"
        disabled
      ></b-form-radio-group>
    </b-form-group>
    <InputText
      textFloat="Title"
      placeholder="Title"
      type="text"
      name="welcome-title"
      class="w-100"
      v-model="list[selectLanguage].title"
      @input="updateField"
    />
    <InputTextArea
      type="text"
      placeholder=""
      class="text-area"
      textFloat="Description"
      rows="12"
      v-model="list[selectLanguage].description"
      @input="updateField"
    />
  </div>
</template>

<script>
export default {
  props: {
    fetchVariable: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      WelcomeOpt: [
        {
          value: 1,
          text: "Welcome Popup",
        },
      ],
      form: { welcome_description: "", welcome_message_type: "1", title: "" },
      list: [
        {
          id: 0,
          language_id: 1,
          title: "",
          description: "",
        },
      ],
      selectLanguage: 0,
      isFetch: false,
    };
  },
  watch: {
    fetchVariable(val) {
      if (val && !this.isFetch) this.getData();
    },
  },
  methods: {
    changeLanguage(val) {
      let index = this.list.findIndex((el) => el.language_id == val);
      this.selectLanguage = index;
    },
    async save() {
      if (this.list.some((el) => el.is_update == 1)) {
        const resp = await this.axios.post(
          "setting/welcomescreen/popup",
          this.list
        );
        return resp;
      }
      //   return true;
    },
    async getData() {
      const res = await this.axios("setting/welcomescreen/popup");
      this.list = res.data.detail;
      this.isFetch = true;
      await this.$nextTick();
      this.changeLanguage(1);
    },
    updateField() {
      this.list[this.selectLanguage].is_update = 1;
    },
  },
};
</script>

<style></style>
