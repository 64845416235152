<template>
  <div>
    <div class="col-6 pl-0 mb-3">
      <InputSelectLanguage
        ref="inputLang"
        @changeLang="changeLanguage"
        v-if="isFetch"
      />
    </div>

    <template v-if="isLoading">
      <OtherLoading />
    </template>
    <template v-else>
      <b-row>
        <div
          class="col-md-6 col-12 d-flex align-items-center"
          v-for="(items, index) of labelList[selectLanguage].label"
          :key="'label' + items.id"
        >
          <InputText
            :textFloat="items.display_label_name || items.label_name"
            :placeholder="items.label_name"
            type="text"
            name="receipt-loading"
            class="w-100"
            v-model="items.name"
            @input="updateField(index)"
          /></div
      ></b-row>
    </template>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    OtherLoading,
  },
  props: {
    form: {
      required: true,
    },
    fetchVariable: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      textEditorField: [
        {
          name: "Header Shipping Address Form",
          key: "header_shipping_address_form",
        },
        {
          name: "Footer Shipping Address Form",
          key: "footer_shipping_address_form",
        },
        {
          name: "Header Billing Address Form",
          key: "header_billing_address_form",
        },
        {
          name: "Footer Billing Address Form",
          key: "footer_billing_address_form",
        },
        {
          name: "Header Contact Address Form",
          key: "header_contact_address_form",
        },
        {
          name: "Footer Contact Address Form",
          key: "footer_contact_address_form",
        },
        {
          name: "Header Register Form",
          key: "header_register_form",
        },
        {
          name: "Footer Register Form",
          key: "footer_register_form",
        },
      ],
      labelList: [],
      tabIndex: 0,
      languageList: [],
      selectLanguage: 0,
      isLoading: true,
      isFetch: false,
    };
  },
  watch: {
    // tabIndex(val) {
    //   if (val == 0)
  },
  watch: {
    fetchVariable(val) {
      if (val && !this.isFetch) this.getList();
    },
  },
  methods: {
    async getList(flag = false) {
      if (this.labelList.length == 0 || flag) {
        this.isLoading = true;
        const resp = await this.axios(`setting/display_language/label`);

        this.labelList = resp.data.detail;
        this.isFetch = true;
        await this.$nextTick();
        this.changeLanguage(1);
        this.isLoading = false;
      }
    },
    changeLanguage(val) {
      let index = this.labelList.findIndex((el) => el.language_id == val);
      this.selectLanguage = index;
    },
    async save() {
      let payload = this.labelList.map((el) => {
        return { ...el, label: el.label.filter((el) => el.is_update == 1) };
      });

      if (payload.some((el) => el.label.length > 0)) {
        const resp = await this.axios.post(
          "setting/display_language/label",
          payload
        );
        return resp;
      }
      return true;
    },
    async translate(text) {
      let res = await this.$Axios.post(
        `https://translation.googleapis.com/language/translate/v2?key=AIzaSyDDKKh7XRhb4jtuLPc4Op3lSJVjWcIUvEE`,
        { q: text, target: "tr" }
      );
      let translation = res.data.data.translations[0].translatedText;
      return translation;
    },

    updateField(index) {
      this.labelList[this.selectLanguage].label[index].is_update = 1;
    },
  },
};
</script>

<style></style>
