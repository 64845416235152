import { render, staticRenderFns } from "./profileDisplay.vue?vue&type=template&id=f011ae1c&scoped=true"
import script from "./profileDisplay.vue?vue&type=script&lang=js"
export * from "./profileDisplay.vue?vue&type=script&lang=js"
import style0 from "./profileDisplay.vue?vue&type=style&index=0&id=f011ae1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f011ae1c",
  null
  
)

export default component.exports