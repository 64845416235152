<template>
  <div>
    <div class="col-6 pl-0 mb-3">
      <InputSelectLanguage
        ref="inputLang"
        @changeLang="changeLanguage"
        v-if="isFetch"
      />
    </div>

    <template v-if="isLoading">
      <OtherLoading />
    </template>
    <template v-else>
      <b-row>
        <div
          class="col-md-6 col-12 d-flex align-items-center"
          v-for="(items, index) of list"
          :key="'label' + items.id + index"
        >
          <InputText
            :textFloat="items.name"
            :placeholder="items.name"
            type="text"
            name="receipt-loading"
            class="w-100"
            v-model="items.customer_menu_language[selectLanguage].name"
            @input="updateField(index)"
          />
        </div>
      </b-row>
    </template>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "CustomerMenu",
  components: { OtherLoading },
  props: {
    fetchVariable: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      list: [
        {
          id: 0,
          customer_menu_type_id: 1,
          name: "",
          icon: "",
          path: "",
          bg_color: "",
          sort_order: 1,
          display: 1,
          customer_menu_language: [
            {
              id: 0,
              language_id: 1,
              customer_menu_id: 1,
              name: "",
            },
          ],
        },
      ],
      selectLanguage: 0,
      isLoading: true,
      isFetch: false,
    };
  },
  watch: {
    fetchVariable(val) {
      if (val && !this.isFetch) this.getData();
    },
  },
  methods: {
    async getData() {
      const res = await this.axios("setting/customer_menu");
      this.list = res.data.detail;
      this.isFetch = true;
      await this.$nextTick();
      this.changeLanguage(1);
      this.isLoading = false;
    },
    changeLanguage(val) {
      let index = this.list[0].customer_menu_language.findIndex(
        (el) => el.language_id == val
      );
      this.selectLanguage = index;
    },
    async save() {
      let payload = this.list.map((el) => {
        return {
          ...el,
          customer_menu_language: el.customer_menu_language
            .map((a) => {
              return {
                ...a,
                name: a.name || el.name,
              };
            })
            .filter((el) => el.is_update == 1),
        };
      });
      if (payload.some((el) => el.customer_menu_language.length > 0)) {
        const resp = await this.axios.post("setting/customer_menu", payload);
        return resp;
      }
      return true;
    },
    updateField(index) {
      this.list[index].customer_menu_language[
        this.selectLanguage
      ].is_update = 1;
    },
  },
};
</script>

<style></style>
